$(document).ready(function () {
	var url = root_url + "?slideshow=1";
	$.get(url, function(data){
		if (data == undefined || data.length == 0)
			return false;
		$('#slideshow-placeholder').replaceWith(data);
		
		var swiper = new Swiper('.swiper-container', {
			pagination: '.swiper-pagination',
			nextButton: '.swiper-button-next',
			prevButton: '.swiper-button-prev',
			paginationClickable: true,
			spaceBetween: 30,
			speed: slideshow_animation_speed,
			centeredSlides: true,
			autoplay: slideshow_change_speed,
			autoplayDisableOnInteraction: false,
			loop:true
		});
	});
});